import React, { ReactNode } from 'react';

import './Row.scss';

interface RowProps {
  children: ReactNode;
}

export const Row = ({ children }: RowProps) => (
  <div className="row">{children}</div>
);
