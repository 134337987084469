import React from 'react';
import { Highlight } from './Highlight';
import './Chonk.scss';

interface TextChonk {
  text: string;
  width: number;
}

interface ChonkProps {
  index: number;
  lineText: TextChonk[];
  noComma?: boolean;
}

export const Appear = ({
  index,
  lineText,
  noComma = false,
}: ChonkProps): any => {
  const texts = lineText.map((t, i) => (
    <span
      style={{ maxWidth: `${t.width}rem` }}
      key={Math.random().toString()}
      className={`appear chonk__appear_${index + i}`}
    >
      <Highlight text={t.text} />
      {noComma ? '' : ', '}
    </span>
  ));
  return <div>{texts}</div>;
};
