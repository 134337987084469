export default [
  [{ text: 'kami boers', width: 13 }],
  [
    { text: 'software engineering', width: 19.25 },
    { text: 'photography', width: 12 },
    { text: 'tea', width: 4 },
  ],
  [
    { text: 'chickens,', width: 8.75 },
    { text: 'oxford commas', width: 14 },
    { text: ',', width: 1 },
  ],
  [{ text: 'and other tomfoolery.', width: 20 }],
];
