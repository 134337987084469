/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';

const HighlightWrapper = styled.span`
  position: relative;
  z-index: 1;
`;

const HighlightText = styled.span`
  position: relative;
  z-index: 2;
  &:hover {
    background-color: RGBA(124, 253, 214, 0.6);
  }
`;

const HighlightColor = styled.span`
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 16px;

  opacity: 0.3;
`;

const DarkHighlightText = styled(HighlightText)`
  &:hover {
    background-color: RGBA(31, 60, 202, 0.4);
  }
`;

const DiscoHighlightText = styled(HighlightText)`
  &:hover {
    // background-color: RGBA(89, 33, 117, 0.5);
    background-color: #ff33ff;
`;
interface HighlightProps {
  text: string;
}

export const Highlight = ({ text }: HighlightProps): any => {
  const currentThemeUI = useThemeUI();
  const current =
    currentThemeUI.colorMode ?? currentThemeUI.theme.initialColorModeName;

  const HighlightTextNode = {
    light: HighlightText,
    dark: DarkHighlightText,
    disco: DiscoHighlightText,
  }[current];

  return (
    <HighlightWrapper>
      <HighlightTextNode>{text}</HighlightTextNode>
      <HighlightColor
        sx={{
          bg: 'highlight',
        }}
      />
    </HighlightWrapper>
  );
};
