import React, { ReactNode } from 'react';

import './IntroLine.scss';

interface IntroLineProps {
  children: ReactNode | ReactNode[];
}

export const IntroLine = ({ children }: IntroLineProps): any => (
  <div className="intro__line">{children}</div>
);
