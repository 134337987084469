/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx } from 'theme-ui';

import { Container } from '../container/Container';
import { Row } from '../row/Row';
import './Intro.scss';

interface IntroProps {
  children: ReactNode;
}

export const Intro = ({ children }: IntroProps) => (
  <Container>
    <div
      className="intro"
      sx={{ borderBottom: '2px solid grey;', borderBottomColor: 'line' }}
    >
      <Row>
        <div className="intro__col">
          <div className="intro__text">{children}</div>
        </div>
      </Row>
    </div>
  </Container>
);
