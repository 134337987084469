import React from 'react';
import { PageProps } from 'gatsby';
import { Appear } from '@/components/intro/Appear';
import { Chonk } from '@/components/intro/Chonk';

import { Intro } from '@/components/intro/Intro';
import { IntroLine } from '@/components/intro/IntroLine';

import text from '../content/splashText';

const Home: React.FC<PageProps> = () => (
  <>
    <Intro>
      <IntroLine>
        <Chonk index={0} noComma lineText={text[0]} />
      </IntroLine>
      <IntroLine>
        <Chonk index={100} lineText={text[1]} />
      </IntroLine>
      <IntroLine>
        <Chonk index={200} lineText={text[2]} noComma />
      </IntroLine>
      <IntroLine>
        <Appear index={300} lineText={text[3]} noComma />
      </IntroLine>
    </Intro>
  </>
);

export default Home;
